/* eslint-disable no-param-reassign */
import * as Babylon from 'babylonjs';
import sceneParams from './sceneParams';
import * as sceneUtils from './sceneUtils';
import { WaterMaterial } from '@babylonjs/materials/water';

export function createScene(engine) {
  const scene = new Babylon.Scene(engine);

  const { backgroundColor } = sceneParams.general;
  scene.clearColor = new Babylon.Color3(backgroundColor.r, backgroundColor.g, backgroundColor.b);
  // scene.ambientColor

  return scene;
}

export function createLights(scene) {
  const {
    hemispheric,
    hemisphericDirection,
    hemisphericIntensity,
    spot,
    spotPosition,
    spotDirection,
    spotAngle,
    spotExponent,
    spotIntensity,
    directional,
    directionalIntensity,
    directionalDirection,
    shadows,
    useContactHardeningShadow,
  } = sceneParams.lightSettings;

  const lights = [];
  const shadowGenerators = [];

  if (hemispheric) {
    const hemisphericLight = new Babylon.HemisphericLight(
      'hemisphericLight',
      hemisphericDirection,
      scene
    );
    hemisphericLight.intensity = hemisphericIntensity;
    lights.push(hemisphericLight);
  }

  if (spot) {
    const spotLight = new Babylon.SpotLight(
      'spotLight',
      spotPosition,
      spotDirection,
      spotAngle,
      spotExponent,
      scene
    );
    spotLight.intensity = spotIntensity;
    lights.push(spotLight);
  }

  if (directional) {
    const directionalLight = new Babylon.DirectionalLight(
      'directionalLight',
      directionalDirection,
      scene
    );
    directionalLight.intensity = directionalIntensity;
    lights.push(directionalLight);
  }

  if (shadows && lights.length > 0) {
    const shadowGenerator = new Babylon.ShadowGenerator(1024, ...lights);
    // shadowGenerator.useContactHardeningShadow = useContactHardeningShadow;
    // shadowGenerator.useBlurCloseExponentialShadowMap = true;
    // shadowGenerator.blurScale = 1;
    // shadowGenerator.usePoissonSampling = true;
    // shadowGenerator.useKernelBlur = true;
    // shadowGenerator.blurKernel = 32;
    shadowGenerator.usePercentageCloserFiltering = true;
    shadowGenerators.push(shadowGenerator);
  }

  return { lights, shadowGenerators };
}

export function createCamera(canvas, scene) {
  const {
    position,
    target,
    attachControl,
    alpha,
    beta,
    radius,
    lowerRadiusLimit,
    upperRadiusLimit,
    maxZ,
    collisionRadius,
    panningSensibility,
    wheelPrecision,
    minZ,
    useBouncingBehavior,
    useFramingBehavior,
    useAutoRotationBehavior,
    upperRadiusTransitionRange,
    lowerRadiusTransitionRange,
    transitionDuration,
    upperAlphaLimit,
    lowerAlphaLimit,
  } = sceneParams.cameraSettings;

  const camera = new Babylon.ArcRotateCamera('camera', alpha, beta, radius, target, scene);
  camera.setPosition(position);
  camera.attachControl(canvas, attachControl);
  camera.radius = radius;
  camera.lowerRadiusLimit = lowerRadiusLimit;
  camera.upperRadiusLimit = upperRadiusLimit;
  camera.maxZ = maxZ;
  camera.minZ = minZ;
  camera.allowUpsideDown = false;
  camera.useAutoRotationBehavior = useAutoRotationBehavior;
  camera.useFramingBehavior = useFramingBehavior;
  camera.useBouncingBehavior = useBouncingBehavior;
  if (useBouncingBehavior) {
    camera.bouncingBehavior.autoTransitionRange = false;
    camera.bouncingBehavior.upperRadiusTransitionRange = upperRadiusTransitionRange;
    camera.bouncingBehavior.lowerRadiusTransitionRange = lowerRadiusTransitionRange;
    camera.bouncingBehavior.transitionDuration = transitionDuration;
  }
  scene.collisionsEnabled = true;
  camera.checkCollisions = true;
  camera.collisionRadius = collisionRadius;
  camera.panningSensibility = panningSensibility;
  camera.wheelPrecision = wheelPrecision;
  camera.upperAlphaLimit = upperAlphaLimit;
  camera.lowerAlphaLimit = lowerAlphaLimit;

  // camera.speed = 2;
  // camera.angularSensibilityX = 0;
  // camera.angularSensibilityY = 0;
  // camera.inputs = Babylon.ArcRotateCameraPointersInput;
  // camera.inputs.multiTouchPanAndZoom = true;

  return { camera, target };
}

export function createHighlighter(scene) {
  const highlight = new Babylon.HighlightLayer('highlight', scene);
  const { highLightStroke } = sceneParams.general;
  highlight.blurHorizontalSize = highLightStroke;
  highlight.blurVerticalSize = highLightStroke;
  return highlight;
}

export function createPostProcesses(camera, scene) {
  const pipeline = new Babylon.DefaultRenderingPipeline('pipeline', true, scene, [camera]);

  pipeline.samples = 4;
  pipeline.imageProcessingEnabled = true;
  pipeline.imageProcessing.toneMappingEnabled = true;
  pipeline.imageProcessing.toneMappingType = Babylon.ImageProcessingConfiguration.TONEMAPPING_ACES;
  pipeline.imageProcessing.exposure = 3;
  pipeline.fxaaEnabled = true;

  // pipeline.bloomEnabled = true;
  // pipeline.bloomThreshold = 0.8;
  // pipeline.bloomWeight = 0.3;
  // pipeline.bloomKernel = 64;
  // pipeline.bloomScale = 0.5;

  const pipelineSSAO = new Babylon.SSAORenderingPipeline('SSAO', scene, 0.75, [camera]);

  scene.postProcessRenderPipelineManager.addPipeline(pipeline);
  scene.postProcessRenderPipelineManager.addPipeline(pipelineSSAO);
  scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline('pipeline', camera);

  return pipeline;
}

export function createEnvironment(scene) {
  const { skyboxSize } = sceneParams.general;
  const cubeTextureURL = '/environment/.dds';

  const skybox = Babylon.Mesh.CreateBox('skyBox', skyboxSize, scene);
  skybox.isPickable = false;
  skybox.infiniteDistance = true;
  // skybox.renderingGroupId = 0;

  const skyboxMaterial = new Babylon.PBRMaterial('skyBoxMaterial', scene);
  skyboxMaterial.backFaceCulling = false;
  skyboxMaterial.reflectionTexture = new Babylon.CubeTexture(cubeTextureURL, scene);
  skyboxMaterial.reflectionTexture.coordinatesMode = Babylon.Texture.SKYBOX_MODE;
  // skyboxMaterial.diffuseColor = new Babylon.Color3(0, 0, 0);
  // skyboxMaterial.specularColor = new Babylon.Color3(0, 0, 0);
  skyboxMaterial.metallic = 1;
  skyboxMaterial.roughness = 1.5; // 1.5;
  // skyboxMaterial.microSurface = 0.7; // 0.1;
  skyboxMaterial.alphaCutOff = 0.4;
  skyboxMaterial.disableLighting = true;
  skybox.material = skyboxMaterial;

  const envTexture = new Babylon.CubeTexture.CreateFromPrefilteredData(cubeTextureURL, scene);
  envTexture.gammaSpace = true;
  envTexture.level = 2;

  scene.environmentTexture = envTexture;

  return skybox;
}

export function createTestGeometry(scene, skybox) {
  // const sphere = Babylon.MeshBuilder.CreateSphere('sphere', { segments: 16, diameter: 2 }, scene);
  // sphere.position.y = 1;

  const backPlane = Babylon.MeshBuilder.CreatePlane(
    'background',
    {
      sideOrientation: Babylon.Mesh.DOUBLESIDE,
      height: 20,
      width: 20,
      updatable: true,
      sourcePlane: new Babylon.Plane(0, 0, 15, 0),
    },
    scene
  );
  const backMat = new Babylon.StandardMaterial('backgroundMat', scene);
  backPlane.material = backMat;
  return backPlane;
  // const sphereMat = sceneUtils.createMaterial(scene, '/', 'sphereMat');
  // const sphereMat = new Babylon.StandardMaterial('refraction', scene);
  // sphereMat.refractionTexture = new Babylon.RefractionTexture('refraction', 1024, scene, true);
  // sphereMat.indexOfRefraction = 0.8;
  // sphereMat.refractionTexture.depth = 1.0;
  // sphereMat.refractionTexture.refractionPlane = new Babylon.Plane(0, 0, -1, 0);
  // sphereMat.refractionTexture.renderList.push(skybox);
  // sphere.material = sphereMat;

  // const liquidSurface = Babylon.MeshBuilder.CreateGround(
  //   'liquid',
  //   { width: 512, height: 512, subdivisions: 32 },
  //   scene
  // );

  // // const liquidSurface = Babylon.Mesh.CreateGround('waterMesh', 512, 512, 32, scene, false);

  // const liquidMaterial = new WaterMaterial(
  //   'liquidMaterial',
  //   scene /* , new Babylon.Vector2(512, 512), */
  // );
  // liquidMaterial.bumpTexture = new Babylon.Texture('/waterbump.png', scene);
  // liquidMaterial.windForce = -10;
  // liquidMaterial.waveHeight = 0.5;
  // liquidMaterial.bumpHeight = 0.1;
  // liquidMaterial.waveLength = 0.1;
  // liquidMaterial.waveSpeed = 50.0;
  // liquidMaterial.colorBlendFactor = 0;
  // liquidMaterial.windDirection = new Babylon.Vector2(1, 1);
  // liquidMaterial.colorBlendFactor = 0;

  // liquidMaterial.addToRenderList(sphere);
  // /*
  //  liquidMaterial.addToRenderList(ground);
  //   liquidMaterial.addToRenderList(skybox); */
  // liquidSurface.material = liquidMaterial;
  // return liquidSurface;
}
export function createBackgroundPlane(scene) {
  const backPlane = Babylon.MeshBuilder.CreatePlane(
    'background',
    {
      sideOrientation: Babylon.Mesh.DOUBLESIDE,
      height: 100,
      width: 100,
      updatable: true,
      sourcePlane: new Babylon.Plane(0, 0, 35, 0),
    },
    scene
  );
  const backMat = new Babylon.StandardMaterial('backgroundMat', scene);
  const backImg = new Babylon.Texture('waterbump.png');
  backMat.diffuseTexture = backImg;
  backPlane.material = backMat;
  return backPlane;
}

export async function loadGlbMeshes(scene, fileName, ids) {
  const rootUrl = '/';

  const loader = Babylon.SceneLoader;
  const plugin = loader.GetPluginForExtension('.glb');
  plugin.coordinateSystemMode = 1;

  const container = await loader
    .LoadAssetContainerAsync(
      rootUrl,
      fileName,
      scene,
      (container) => container, // object, // onSuccess
      // loadingCb, // onProgress
      // errorCb, // onError
      '.glb'
    )
    .catch((error) => console.log('ERROR ON FIFO', fileName, error));

  const { meshes } = container;
  console.log('meshes', meshes);
  if (ids) {
    const idMeshes = []
    ids.forEach((id) => {
      const idMesh = meshes.find((m) => m.id === id);
      console.log('idMesh', idMesh);
      scene.addMesh(idMesh, true);
      idMeshes.push(idMesh)
    });
    return idMeshes;
  } else {
    const rootMesh = container.createRootMesh();
    scene.addMesh(rootMesh, true);
  }

  // loader.LoadAsync(rootUrl, scene, Filename,fileName, engine, '.glb')
  return meshes;
}

export function createRefractionMaterial(scene, renderObjects = []) {
  const refractionMat = new Babylon.PBRMaterial('crystal', scene);
  refractionMat.diffuseColor = new Babylon.Color3(1, 1, 1);
  const refractionTex = new Babylon.RefractionTexture('refraction', 1024, scene, true);
  refractionMat.indexOfRefraction = 0.8;
  refractionTex.depth = 5.0;
  refractionTex.refractionPlane = new Babylon.Plane(0, 0, 5, 0);

  renderObjects.forEach((o) => {
    refractionTex.renderList.push(o);
  });

  refractionMat.refractionTexture = refractionTex;

  return refractionMat;
}

export default {
  createEnvironment,
  createScene,
  createLights,
  createCamera,
  createHighlighter,
  createPostProcesses,
  createTestGeometry,
};
