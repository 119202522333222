import * as Babylon from 'babylonjs';
import 'babylonjs-loaders';
import 'babylonjs-materials';

import sceneParams from './sceneParams';
import * as sceneCreation from './sceneCreation';
import * as sceneUtils from './sceneUtils';

class Engine {
  constructor(canvas) {
    this.canvas = canvas;
    this.engine = new Babylon.Engine(this.canvas, true);

    this.doDebug = false;

    this.onClick = this.onClick.bind(this);
    this.onDoubleClick = this.onDoubleClick.bind(this);
  }

  async createScene() {
    this.scene = sceneCreation.createScene(this.engine);

    const { camera, target } = sceneCreation.createCamera(this.canvas, this.scene);
    this.camera = camera;
    this.target = target;

    const { lights, shadowGenerators } = sceneCreation.createLights(this.scene);
    this.lights = lights;
    this.shadowGenerators = shadowGenerators;

    // this.postProcesses = sceneCreation.createPostProcesses(this.camera, this.scene);
    // this.skybox = sceneCreation.createEnvironment(this.scene);

    this.loadModel();

    this.resize = () => {
      this.engine.resize();
    };

    if (this.doDebug) {
      this.debug();
    }

    console.log('SCENE', this.scene);
  }

  async loadModel() {
    // this.backPlane = sceneCreation.createBackgroundPlane(this.scene);
    const meshes = await sceneCreation.loadGlbMeshes(
      this.scene,
      'soon.glb', // 'doubtfulbit.glb',
      ['Doubtful bit', 'Soon']
    )

    // this.comming = meshes.find((m) => m.id === 'Text');
    /* sceneUtils.animateRotation(this.comming);
this.scene.beginAnimation(this.comming, 0, 100, true); */

    const refractionMat = sceneCreation.createRefractionMaterial(this.scene, [
      /* this.skybox,  */ this.backPlane,
      ...meshes,
    ]);
    meshes.forEach((m) => (m.material = refractionMat));
  }

  doRender() {
    this.engine.runRenderLoop(() => {
      this.scene.render();
      this.time = this.engine.getDeltaTime();
      if (this.comming) {
        this.comming.rotation.y += 1;
      }
    });
    this.registerEvents();
  }

  onClick() {
    //
  }

  onDoubleClick() {
    //
  }

  registerEvents() {
    window.addEventListener('resize', this.resize);
    window.addEventListener('click', this.onClick);
    window.addEventListener('dblclick', this.onDoubleClick);
  }

  dispose() {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('click', this.onClick);
    window.removeEventListener('dblclick', this.onDoubleClick);
  }

  debug() {
    this.scene.debugLayer.show({
      overlay: true,
      // globalRoot: document.getElementById('viewer'),
    });
  }
}

export default Engine;
