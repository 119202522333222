import React from 'react';

import './styles/viewer.scss';

import Canvas from './components/Canvas';

const backgroundImg = [
  'background-image: url("https://media.giphy.com/media/xUA7aKCtqnlAzuIg8M/giphy.gif")',
  'background-size: cover',
  'background-position: center',
  '-webkit-text-stroke: 2px black',
];
const signatureStyle = [
  'color: #fff',
  'background: #000',
  'font-size: 50px',
  'font-family: "Nova Cut", cursive',
  'padding: 30px 10px',
  ...backgroundImg,
];

const contactStyle = ['color: #fff', 'background: #000', 'font-size: 20px'];

console.log('%cCreated by Lau & Ale', signatureStyle.join(';'));
console.log('%cContact us at doubtfulbinarydigit@gmail.com 💻', contactStyle.join(';'));


function App() {
  return (
    <div className="App">
      <React.Fragment>
        <Canvas></Canvas>
      </React.Fragment>
    </div>
  );
}

export default App;
