import React from 'react';
import PropTypes from 'prop-types';
import useEngine from '../hooks/useEngine';


export default function Canvas(props) {
  const refCanvas = useEngine(props);

  return (
    <div id="viewer">
      <canvas id="renderCanvas" ref={refCanvas} style={{ width: '100%', height: '100%' }}/>
    </div>
  );
}

Canvas.propTypes = {}

Canvas.defaultProps = {}