import * as Babylon from 'babylonjs';

const sceneParams = {
  general: {
    backgroundColor: { r: 1, g: 1, b: 1, a: 0 },
    highLightColor: Babylon.Color3.Yellow(),
    highLightStroke: 1, // 0.15,
    skyboxSize: 200,
    overlayColor: Babylon.Color3.Blue(),
    overlayAlpha: 0.2,
    outlineColor: Babylon.Color3.Blue(),
    outlineWidth: 1,
    scaleModel: true,
    scaling: new Babylon.Vector3(0.01, 0.01, 0.01),
  },
  cameraSettings: {
    target: new Babylon.Vector3(0, 0, 0),
    position: new Babylon.Vector3(0, 0, 15),
    alpha: 0, // Defines the camera rotation along the longitudinal axis
    beta: 0, // Defines the camera rotation along the latitudinal axis
    radius: 25, // Defines the camera distance from its target
    attachControl: true,
    lowerRadiusLimit: 1, // 0.1
    upperRadiusLimit: 30,
    maxZ: 1050,
    minZ: 0.001,
    collisionRadius: new Babylon.Vector3(0.0001, 0.0001, 0.0001),
    panningSensibility: 4000, // 5000
    wheelPrecision: 100,
    useBouncingBehavior: false,
    useAutoRotationBehavior: false,
    useFramingBehavior: false,
    upperRadiusTransitionRange: -0.1,
    lowerRadiusTransitionRange: 0.1,
    transitionDuration: 250,
    upperBetaLimit: (-3 * Math.PI) / 4,
    lowerBetaLimit: (-3 * Math.PI) / 4,
    upperAlphaLimit: (3 * Math.PI) / 4,
    lowerAlphaLimit: Math.PI / 4,
  },
  lightSettings: {
    hemispheric: false,
    spot: false,
    directional: true,
    shadows: false,
    useContactHardeningShadow: false,
    hemisphericDirection: new Babylon.Vector3(-0.5, 1, 0),
    hemisphericIntensity: 0.1,
    spotPosition: new Babylon.Vector3(0.02, 1.66, 0.29),
    spotDirection: new Babylon.Vector3(0.29, -0.52, -0.8),
    spotIntensity: 2,
    spotExponent: 50,
    spotAngle: Math.PI / 4,
    directionalIntensity: 2,
    directionalDirection: new Babylon.Vector3(0.29, -0.52, -0.8),
  },
  tools: {
    thumbnailRes: 400,
  },
  materials: {
    liquidMat: {
      // metallic: 0.01,
      roughness: 2, // 0.99,
      subSurface: {
        isTranslucencyEnabled: true,
        translucencyIntensity: 0.8,
        tintColor: Babylon.Color3.Red(),
        minimumThickness: 0.1,
        maximumThickness: 3,
        useMaskFromThicknessTexture: true,
      },
    },
    lineMaterialProps: {
      metallic: 0.1,
      roughness: 0.9,
      disableDepthWrite: false,
    },
  },
  lines: {
    resolution: 8,
    width: 1,
    colorArteries: new Babylon.Color3(1, 0, 0),
    colorVeins: new Babylon.Color3(0, 0, 1),
    colorNerves: new Babylon.Color3(1, 1, 0),
    colorGanglion: new Babylon.Color3(0, 1, 0),
  },
};

export default sceneParams;
