import React from 'react';
import Engine from '../engine';

export default function useEngine(props) {
  const refCanvas = React.createRef();

  React.useEffect(() => {
    if (refCanvas.current) {
      const canvas = refCanvas.current;
      const createdEngine = new Engine(canvas);
      createdEngine.createScene();

      createdEngine.doRender();
      return () => {
        createdEngine.dispose();
      };
    }
    throw Error('No canvas reference set');
  }, []);

  return refCanvas
}